<template>
  <form
    ref="form"
    @submit.prevent="next"
  >
    <c-title>Antragsformular</c-title>

    <p class="mt-8 mb-8">
      Alle Felder außer den explizit ausgenommenen sind Pflichtfelder.
    </p>
    <c-select
      v-model="fields.salutation"
      :errors="errors.salutation"
      class="mb-8"
      label="Anrede"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.name"
      :errors="errors.name"
      class="mb-8"
      label="Vorname"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.surname"
      :errors="errors.surname"
      class="mb-8"
      label="Nachname"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.address_additional_info"
      :errors="errors.address_additional_info"
      class="mb-8"
      label="Evtl. Adresszusatz"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.address"
      :errors="errors.address"
      class="mb-8"
      label="Straße und Hausnummer"
      autocomplete="street-address"
      maxlength="100"
      required
    />
    <c-form-columns :columns="[1, 5]">
      <c-form-text
        v-model="fields.postal_code"
        :errors="errors.postal_code"
        class="mb-8"
        label="Postleitzahl"
        autocomplete="postal-code"
        inputmode="numeric"
        pattern="[0-9]*"
        minlength="5"
        maxlength="5"
        required
      />
      <c-form-text
        v-model="fields.city"
        :errors="errors.city"
        class="mb-8"
        label="Ort"
        autocomplete="address-level2"
        maxlength="100"
        required
      />
    </c-form-columns>
    <c-form-text
      v-model="fields.contact_number"
      :errors="errors.contact_number"
      class="mb-8"
      label="Telefonnummer"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.email"
      :errors="errors.email"
      class="mb-8"
      label="E-Mail-Adresse"
      type="email"
      autocomplete="email"
      required
    />
    <c-form-text
      v-model="fields.website"
      :errors="errors.website"
      class="mb-8"
      label="Website/Internetauftritt (optional)"
      autocomplete="honorific-suffix"
      type="url"
      placeholder="https://example.com"
    />
    <c-form-text
      v-model="fields.motivational_letter"
      :errors="errors.motivational_letter"
      class="mb-8"
      label="Motivationsschreiben"
      help="Begründung der Bewerbung für das Atelierstipendium; Beschreibung des künstlerischen Vorhabens, an dem Sie arbeiten; max. 3.500 Zeichen inkl. Leerzeichen"
      autocomplete="honorific-suffix"
      required
      tag="textarea"
      maxlength="3500"
      rows="30"
    />
    <c-form-text
      :errors="errors.vitae_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.vitae_file"
      label="Künstlerische Vita"
      :help="`Mit Angaben zum Studium/Abschluss und – falls vorhanden – Website, Ausstellungen, Preise, Auszeichnungen oder Stipendien. Erlaubte Dateiendungen: PDF. Maximale Dateigröße: 2 MB.`"
      accept=".pdf"
      required
      @change="fields.vitae_file = $event"
    />
    <c-form-text
      :errors="errors.portfolio_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.portfolio_file"
      label="Portfolio"
      :help="`Erlaubte Dateiendungen: PDF. Maximale Dateigröße: 5 MB.`"
      accept=".pdf"
      required
      @change="fields.portfolio_file = $event"
    />
    <c-button>
      Nächster Schritt
    </c-button>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep0',

  extends: BRequestStep,

  data () {
    return {
      fields: {
        salutation: '',
        name: '',
        surname: '',
        address: '',
        contact_number: '',
        email: '',
        website: '',
        motivational_letter: '',
        vitae_file: '',
        portfolio_file: ''
      }
    }
  },

  computed: {
    step: () => 0,
    id: () => 'reportData'
  }
}
</script>
